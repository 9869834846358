import gameActiveImage from '../img/games_active.png'
import gameInactiveImage from '../img/games_inactive.png'

import casinoActiveImage from '../img/casino_active.png'
import promoActiveCasinoImage from '../img/promo_active_casino.png'
import casinoInactiveImage from '../img/casino_inactive.png'
import promoInactiveImage from '../img/promo_inactive.png'
import '../css/bottom_menu.css'



function BottomMenuCasino({activeSubcategory, setActiveSubcategory, countryName}) {

    return (
        <div className="bottom-menu">
            <div className="bottom-menu-item-container">
                <div className="bottom-menu-item-image-container" onClick={() => setActiveSubcategory('Игры')}>
                    <img className="bottom-menu-item-image" src = {activeSubcategory === 'Игры' ? gameActiveImage : gameInactiveImage } alt='error'/>
                </div>
                <div className='bottom-menu-item-title' style={{color: activeSubcategory === 'Игры' ? '#05D661': '#FFFFFF'}}> {countryName === 'russia' ? 'Игры': 'Games'}</div>
            </div>
            <div className="bottom-menu-item-container" onClick={() => setActiveSubcategory('Казино')}>
                <div className="bottom-menu-item-image-container">
                    <img className="bottom-menu-item-image" src = {activeSubcategory === 'Казино' ? casinoActiveImage : casinoInactiveImage } alt='error'/>
                </div>
                <div className='bottom-menu-item-title' style={{color: activeSubcategory === 'Казино' ? '#05D661': '#FFFFFF'}}>{countryName === 'russia' ? 'Казино': 'Casino'}</div>
            </div>
            <div className="bottom-menu-item-container" onClick={() => setActiveSubcategory('Промо')}>
                <div className="bottom-menu-item-image-container">
                    <img className="bottom-menu-item-image" src = {activeSubcategory === 'Промо' ? promoActiveCasinoImage : promoInactiveImage } alt='error'/>
                </div>
                <div className='bottom-menu-item-title' style={{color: activeSubcategory === 'Промо' ? '#05D661': '#FFFFFF'}}>{countryName === 'russia' ? 'Промо': 'Promo'}</div>
            </div>

        </div>
    )

};

export default BottomMenuCasino;