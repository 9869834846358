import BottomMenuCasino from "./components/BottomMenuCasino";
import UpperMenu from "./components/UpperMenu";

import BottomMenuBetting from "./components/BottomMenuBettings";
import GamesContainer from "./components/GamesContainer";
import { useState, useEffect } from "react";
import CardsContainer from "./components/CardsContainer";
import Promo from "./components/Promo";
import restApiHost from ".";
import SearchBar from "./components/SearchBar";
import EmptySearch from "./components/EmptySearch";
import CasinoCardsContainer from "./components/CasinoCardsContainer";


function App() {
  const [activeCategory, setActiveCategory] = useState('Казино');
  const [activeSubcategory, setActiveSubcategory] = useState('Казино');

  const [games, setGames] = useState(null);
  const [casino, setCasino] = useState(null);
  const [bettings, setBetting] = useState(null);
  const [countryName, setCountryName] = useState(null);

  useEffect(()  => {

    async function fetchCountryName() {
      const response = await fetch('https://zaymi-online24.store/geo/get_country_name/');
      const data = await response.json();
      const country = data.country_name.toLowerCase();
      setCountryName(country);
    }
    
    async function fetchData () {
      const response = await fetch(restApiHost + '/api/get-main-page-data/' + countryName + '/');
      const data = await response.json();
      const games = data.games.map(item => ({ 
        ...item, 
        isClicked: false 
      }));
      setGames(games);
      const casino = data.casino.map(item => ({ 
        ...item, 
        isClicked: false 
      }));
      setCasino(casino);
      const betting = data.betting.map(item => ({ 
        ...item, 
        isClicked: false 
      }));
      setBetting(betting);
    };

    if (countryName === null) {
      fetchCountryName();
    }

    if (countryName !== null && games === null) {
      fetchData()
    }

  }, [countryName, games]);


  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event) => {
      setInputValue(event.target.value);
  };

  const ClearInput = () => {
      setInputValue('')
      
  }
  
  const filteredOfers = (games || []).filter(offer => {
      return [offer.title, ...offer.title_for_look].some(item =>
          item.toLowerCase().includes(inputValue.toLowerCase())
      );})

  return (
    <div className="app">
      <UpperMenu activeCategory={activeCategory} setActiveCategory={setActiveCategory} setActiveSubcategory={setActiveSubcategory} countryName={countryName}/>
      

      {activeCategory === 'Казино' && activeSubcategory === 'Игры' && games !== null && ( 
        <div>
          {inputValue === '' ?(
          <GamesContainer games={games}/>
          ) : (<div>
            {filteredOfers.length > 0 ? (
              <GamesContainer games={filteredOfers}/>
            ) : (
              <EmptySearch countryName={countryName}/>
            )
            }
          </div>)}
          <SearchBar inputValue={inputValue} setInputValue={setInputValue} handleInputChange={handleInputChange} ClearInput={ClearInput} countryName={countryName}/>
        </div>
      )}

      
      {activeCategory === 'Казино' && activeSubcategory === 'Казино' && casino !== null&&( 
        <CasinoCardsContainer games={activeCategory === 'Казино' ? casino: bettings} setGames={activeCategory === 'Казино' ? setCasino: setBetting} activeCategory={activeCategory}/>
      )}
      {activeCategory === 'Спорт' && activeSubcategory === 'Спорт' && bettings!== null &&( 
        <CardsContainer games={activeCategory === 'Казино' ? casino: bettings} setGames={activeCategory === 'Казино' ? setCasino: setBetting} activeCategory={activeCategory}/>
      )}
      {activeSubcategory === 'Промо' && ( 
        <Promo countryName={countryName}/>
      )}

      {activeCategory === 'Казино' ? (
        <BottomMenuCasino activeSubcategory={activeSubcategory} setActiveSubcategory={setActiveSubcategory} countryName={countryName}/>
      ) : (
        <BottomMenuBetting activeSubcategory={activeSubcategory} setActiveSubcategory={setActiveSubcategory} countryName={countryName}/>
      )}
    </div>
  );
}

export default App;
