import '../css/promo.css'

function Promo ({countryName}) {

    return (
        <div className='promo-area'>
            <div className="promo-text">{countryName === 'russia' ? 'В разработке...': 'Coming soon...'}</div>
        </div>
    )
};

export default Promo;