import '../css/games_container.css'

function GamesContainer({games}) {

  const chunkArray = (array, chunkSize) => {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  };

  // Разбиваем массив игр на группы по 3 элемента
  const gameChunks = chunkArray(games, 3);

  const navigateToPage = (link) => {
    window.open(link, '_self');
  }

  return (
    <div className="games-container">
        {gameChunks.map((chunk, index) => (
          <div className="games-container-row" key={index}>
            {chunk.map((game, idx) => (
              <div
                className="games-container-item"
                onClick = {() => {navigateToPage(game.link)}}
                key={idx}
                style={{
                  backgroundImage: `url(${game.image})`, // Используем изображение из объекта game
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  
                }}
              />
            ))}
          </div>
        ))}
    </div>
    )

};

export default GamesContainer;