import finderImage from '../img/finder.png'
import crossImage from '../img/cross.png'
import '../css/searchbar.css'


function SearchBar({inputValue, setInputValue, handleInputChange, ClearInput, countryName}) {


    return (
        <div className="searchbar">
            <input className="searchbar-input" value={inputValue} onChange={handleInputChange} placeholder={countryName === 'russia' ? "Найдите свою игру": "Find your game"}></input>
            {inputValue === '' ? (
                <div className="searchbar-image-container">
                    <img className="searchbar-image" src={finderImage} alt = 'error'></img>
                </div>
            ) : (
                <div className="searchbar-image-container-cross" onClick={ClearInput}>
                    <img className="searchbar-image" src={crossImage} alt = 'error'></img>
                </div>
            )}
        </div>
    )
};

export default SearchBar;