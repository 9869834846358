import '../css/upper_menu.css'
import casinoActiveImage from '../img/casino_background_active.png'
import bettingActiveImage from '../img/betting_background_active.png'
import bettingsInactiveImage from '../img/betting_background_inactive.png'
import casinoInactiveImage from '../img/casino_background_inactive.png'


function UpperMenu({activeCategory, activeSubcategory, setActiveCategory, setActiveSubcategory, countryName}) {
    return (
        <div className="upper-menu">

            <div className="upper-menu-item" onClick={() => {setActiveCategory('Казино'); setActiveSubcategory('Казино')} } style={{
                        backgroundImage: `url(${activeCategory === 'Казино' ? casinoActiveImage : casinoInactiveImage})`, // Используем импортированное изображение
                        backgroundSize: 'cover', // Настройка размера
                        backgroundPosition: 'center', // Центрирование изображения
                      }}>
                <div className="upper-menu-item-title">{countryName === 'russia' ? 'КАЗИНО': "CASINO"}</div>
            </div>
            
            <div className="upper-menu-item" onClick={() => {setActiveCategory('Спорт'); setActiveSubcategory('Спорт')}} style={{
                        backgroundImage: `url(${activeCategory === 'Спорт' ? bettingActiveImage : bettingsInactiveImage})`, // Используем импортированное изображение
                        backgroundSize: 'cover', // Настройка размера
                        backgroundPosition: 'center', // Центрирование изображения
                      }}>
               
                    <div className="upper-menu-item-title">{countryName === 'russia' ? 'СПОРТ': "SPORT"}</div>  
                
            </div>
        </div>
    )
};

export default UpperMenu;