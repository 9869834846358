import promoActiveSportImage from '../img/promo_active_sport.png'
import promoInactiveImage from '../img/promo_inactive.png'

import sportActiveImage from '../img/sport_active.png'
import sportInactiveImage from '../img/sport_inactive.png'

import '../css/bottom_menu.css'



function BottomMenuBetting({activeSubcategory, setActiveSubcategory, countryName}) {

    return (
        <div className="bottom-menu">
            <div className="bottom-menu-item-container" onClick={() => setActiveSubcategory('Спорт')}>
                <div className="bottom-menu-item-image-container">
                    <img className="bottom-menu-item-image" src = {activeSubcategory === 'Спорт' ? sportActiveImage : sportInactiveImage } alt='error'/>
                </div>
                <div className='bottom-menu-item-title' style={{color: activeSubcategory === 'Спорт' ? '#FE8900': '#FFFFFF'}}>{countryName === 'russia' ? 'Спорт': 'Sport'}</div>
            </div>
            <div className="bottom-menu-item-container" onClick={() => setActiveSubcategory('Промо')}>
                <div className="bottom-menu-item-image-container">
                    <img className="bottom-menu-item-image" src = {activeSubcategory === 'Промо'  ?  promoActiveSportImage : promoInactiveImage } alt='error'/>
                </div>
                <div className='bottom-menu-item-title' style={{color: activeSubcategory === 'Промо' ? '#FE8900': '#FFFFFF'}}>{countryName === 'russia' ? 'Промо': 'Promo'}</div>
            </div>

        </div>
    )

};

export default BottomMenuBetting;