import '../css/empty_search.css'


function EmptySearch({countryName}){

    return (
        <div className="empty-search">
            <div className="empty-search-text">{countryName === 'russia' ? 'По вашему запросу игр не найдено': 'No games found for your request'}</div>
        </div>
    )
};
export default EmptySearch;